import { atom } from 'jotai';
import {
  type UserModel,
  GameItemDTOList,
  UserGameStatusDTO,
} from '@/apis/login';
import { getLocalStorage, setLocalStorage } from '@/utils/localStoreHelper';
import { EasterEggDataDTO } from '@/apis/easterEgg';

export const userInfoAtom = atom<UserModel>({
  tgId: '',
  name: '',
  headerUrl: '',
  registerIp: '',
  registerCity: '',
  registerCountry: '',
  registerTime: '',
  gold: '',
  bananaCount: '',
  depositAmount: '',
  bananaOnlineId: '',
  claimEasterEgg: false,
  skipToday: false,
  skipNum: 0,
});

export const userGameStatus = atom<UserGameStatusDTO>({
  playTimes: '',
  successTimes: '',
  lastSpinTime: '',
  playFreeTimes: '',
  reviveEarnMethod: [],
  ticketEarnMethod: [],
  spinTimes: '',
  journey: {
    index: undefined,
    difficultyCoefficient: undefined,
    nextPrice: '',
    nextRewards: '',
    currentLevel: undefined,
    isLastLevel: false,
  },
});

export const userEasterEggInfoAtom = atom<EasterEggDataDTO>({
  tgId: '',
  pass: '',
  expireTime: '',
  users: [],
  myBananaId: '',
  myGold: '',
  link: '',
});

export const gameItemDTOListAtom = atom<GameItemDTOList[]>([]);

const tokenAtom = atom(getLocalStorage('token') ?? '');
export const tokenManager = atom(
  (get) => get(tokenAtom),
  (get, set, newToken: string) => {
    set(tokenAtom, newToken);
    setLocalStorage('token', newToken);
  },
);

export const cloudflareTurnstileTokenAtom = atom("turn off");
